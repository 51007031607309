var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"send-message-calculate-target"},[_c('select-input',{attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        model: _vm.model,
        'key'       : 'targetType',
        'options'   : _vm.actonCalculatedTarget,
        'disabled'  : _vm.disabled,
        'clearable' : false,
        'multiple'  : false
      }
    }}}),_c('action-text',{attrs:{"disabled":_vm.disabled,"title-prefix":"send_message_to_calculated_target_action","placeholders":_vm.TRIGGERS_PLACEHOLDERS},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
      'func': _vm.messageEditorToPlainSetter,
      'args': Object.assign({}, {model: _vm.model,
        'key': 'failedText',
        'prefix': 'send_message_to_calculated_target_action_',
        'placeholders': _vm.TRIGGERS_PLACEHOLDERS,
        'targetToUpload': _vm.groupUploadTarget,
        'editorMode': _vm.MediaEditorMode.TelegramShort,
        'validation': 'required',
        'availableButtonsTypes': _vm.newDefaultMessageEditorButtons,
        'base-api-url': 'api.chatkeeper.app/cabinet',
        'options': {
          topics: _vm.topicsAvailable
        }},
        _vm.topicsIfForum),
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }