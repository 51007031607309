var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-user-triggers-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.pageMiniHelpMessageKey) ? _vm.$t(_vm.module.model.pageMiniHelpMessageKey) : null,"help-message":_vm.$te(_vm.module.model.pageHelpMessageKey) ? _vm.$t(_vm.module.model.pageHelpMessageKey) : null},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{attrs:{"disabled":_vm.disabled},on:{"save-module":_vm.saveModule}})]},proxy:true}])}),_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('a-card',{staticClass:"mt-5"},[_c('select-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'after_first_enter_trigger',
          'tariffTags': _vm.getTagsByFieldKey('after_greeting_triggers'),
          'hasAnchor': true,
          'settingWrapper': {
            'customiseValue': _vm.activeTriggersOptions[0],
            'value': { value: '' },
          },
          'notFoundOptionText': this.$t('trigger_option_not_found'),
          'disabled': _vm.disabled
        }
      }}}),_c('select-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'after_enter_trigger',
          'tariffTags': _vm.getTagsByFieldKey('after_greeting_triggers'),
          'hasAnchor': true,
          'settingWrapper': {
            'customiseValue': _vm.activeTriggersOptions[0],
            'value': { value: '' },
          },
          'notFoundOptionText': this.$t('trigger_option_not_found'),
          'disabled': _vm.disabled
        }
      }}}),_c('select-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'after_not_first_enter_trigger',
          'tariffTags': _vm.getTagsByFieldKey('after_greeting_triggers'),
          'hasAnchor': true,
          'settingWrapper': {
            'customiseValue': _vm.activeTriggersOptions[0],
            'value': { value: '' },
          },
          'notFoundOptionText': this.$t('trigger_option_not_found'),
          'disabled': _vm.disabled
        }
      }}}),_c('a-divider'),_c('select-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'after_first_captcha_success_trigger',
          'tariffTags': _vm.getTagsByFieldKey('after_greeting_triggers'),
          'hasAnchor': true,
          'settingWrapper': {
            'customiseValue': _vm.activeTriggersOptions[0],
            'value': { value: '' },
          },
          'notFoundOptionText': this.$t('trigger_option_not_found'),
          'disabled': _vm.disabled
        }
      }}}),_c('select-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'after_captcha_success_trigger',
          'tariffTags': _vm.getTagsByFieldKey('after_greeting_triggers'),
          'hasAnchor': true,
          'settingWrapper': {
            'customiseValue': _vm.activeTriggersOptions[0],
            'value': { value: '' },
          },
          'notFoundOptionText': this.$t('trigger_option_not_found'),
          'disabled': _vm.disabled
        }
      }}}),_c('select-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'after_not_first_captcha_success_trigger',
          'tariffTags': _vm.getTagsByFieldKey('after_greeting_triggers'),
          'hasAnchor': true,
          'settingWrapper': {
            'customiseValue': _vm.activeTriggersOptions[0],
            'value': { value: '' },
          },
          'notFoundOptionText': this.$t('trigger_option_not_found'),
          'disabled': _vm.disabled
        }
      }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'after_captcha_message',
          'targetToUpload': _vm.groupUploadTarget,
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
          'disabled': _vm.disabled
        },
      }}}),_c('multi-message-editor-with-media-input',{staticClass:"mb-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'after_fail_captcha_message',
          'targetToUpload': _vm.groupUploadTarget,
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
          'disabled': _vm.disabled
        },
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }