var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('multi-message-editor-with-media-input',{staticClass:"punish-message mt-0",attrs:{"setup":{
    'func': _vm.newConfigInputSetup,
    'args': {
      'model': this,
      'key': 'punishMessage',
      'prefix': _vm.prefix,
      'placeholders': _vm.placeholdersOrBase,
      'targetToUpload': _vm.groupUploadTarget,
      'base-api-url': 'api.chatkeeper.app/cabinet',
      'validation': _vm.validationRules,
      'availableButtonsTypes': _vm.buttonsOrBase,
      'blacklistMediaButtonTypes': _vm.blacklistedFileButtonsByLicense,
      defaultValue: _vm.defaultModel
    }
  }}})}
var staticRenderFns = []

export { render, staticRenderFns }