var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{attrs:{"title":_vm.$t('bot_settings_extra_send_message_title')}},[_c('message-editor-with-media-input',{staticClass:"mt-0",attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        model: _vm.model,
        'key': 'message',
        'availableButtonsTypes': _vm.newDefaultMessageEditorButtons,
        'targetToUpload': _vm.groupUploadTarget,
        'base-api-url': 'api.chatkeeper.app/cabinet',
        'hasAnchor': true,
        'blacklistMediaButtonTypes': _vm.blacklistedFileButtonsByLicense,
        'settingsStructure': {
          remove_after        : 0,
          send_after          : 0,
          pin                 : false,
          disable_link_preview: false,
          disable_notify      : false,
        },
        'disabled': _vm.disabled
      }
    }}}),_c('div',{staticClass:"flex flex-col xs:flex-row items-start justify-end"},[_c('a-button',{staticClass:"btn-warning w-full md:w-auto",attrs:{"icon":"message","disabled":_vm.disabled},on:{"click":_vm.sendChatMessage}},[_vm._v(" "+_vm._s(_vm.$t("bot_settings_extra_send_message_button_send_chat"))+" ")]),_c('a-button',{staticClass:"btn-success mt-2 xs:mt-0 xs:ml-2  w-full md:w-auto",attrs:{"icon":"message","disabled":_vm.disabled},on:{"click":_vm.sendSelfMessage}},[_vm._v(" "+_vm._s(_vm.$t("bot_settings_extra_send_message_button_send_self"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }